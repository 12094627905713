<template>
    <svg width="238" height="211" viewBox="0 0 238 211" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.3" filter="url(#filter0_iiii_755_6046)">
        <path d="M56.0881 183.024H79.3761C95.1201 183.024 102.992 174.933 102.992 158.752V52.1519C102.992 35.9706 95.1201 27.88 79.3761 27.88H56.0881C40.344 27.88 32.472 35.9706 32.472 52.1519V158.752C32.472 174.933 40.344 183.024 56.0881 183.024ZM83.9681 210.904H51.168C34.9867 210.904 22.4134 206.421 13.448 197.456C4.48267 188.49 0 176.026 0 160.064V50.8399C0 34.6586 4.48267 22.1946 13.448 13.448C22.4134 4.48266 34.9867 0 51.168 0H83.9681C99.9308 0 112.395 4.48266 121.36 13.448C130.325 22.1946 134.808 34.6586 134.808 50.8399V160.064C134.808 176.026 130.325 188.49 121.36 197.456C112.395 206.421 99.9308 210.904 83.9681 210.904Z" fill="#010013"/>
        <path d="M163.449 51.4959V32.144C163.449 28.6453 164.98 26.0213 168.041 24.272L202.153 3.60799C206.308 1.20266 209.807 0 212.649 0H232.329C236.047 0 237.905 1.85866 237.905 5.57598V205.328C237.905 209.045 236.156 210.904 232.657 210.904H211.337C207.62 210.904 205.761 209.045 205.761 205.328V75.4061C205.761 57.0582 185.895 45.5945 170.009 54.7759C165.636 56.7439 163.449 55.6506 163.449 51.4959Z" fill="#010013"/>
        </g>
        <defs>
        <filter id="filter0_iiii_755_6046" x="-11.2" y="-8.91429" width="249.105" height="228.504" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="11.6571"/>
        <feGaussianBlur stdDeviation="4.34286"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.494118 0 0 0 0 0.00784314 0 0 0 0 0.960784 0 0 0 1 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_755_6046"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1.14286"/>
        <feGaussianBlur stdDeviation="2.85714"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.976471 0 0 0 0 0.976471 0 0 0 0.88 0"/>
        <feBlend mode="normal" in2="effect1_innerShadow_755_6046" result="effect2_innerShadow_755_6046"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1.14286"/>
        <feGaussianBlur stdDeviation="0.457143"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.08 0"/>
        <feBlend mode="normal" in2="effect2_innerShadow_755_6046" result="effect3_innerShadow_755_6046"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="-11.2" dy="-8.91429"/>
        <feGaussianBlur stdDeviation="11.6571"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.752941 0 0 0 0 0.0156863 0 0 0 0 0.996078 0 0 0 0.6 0"/>
        <feBlend mode="normal" in2="effect3_innerShadow_755_6046" result="effect4_innerShadow_755_6046"/>
        </filter>
        </defs>
    </svg>
</template>